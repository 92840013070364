export const primary = '#585858';
export const primary2 = '#F6F6F6';
export const primary3 = '#0BBC7C'; //DRAG_nDROP+BUTN
export const primary35 = '#C8C8C8';
export const primary4 = '#051210'; //OOLE_FB_ALLWORD
export const primary45 = '#2f5650'; //GLOBAL?_BASE_COLOR
export const primary46 = '#426558'; //BUTTON_onHOVER

export const primary15 = '#72D2BB'; //OOLE_FB_BACKGR

export const primary5 = '#A4A4A4';
export const primary6 = '#1B3028';

// export const primary = '#666';
// export const primary2 = '#EEE';
// export const primary3 = '#CCC';
// export const primary4 = '#AAA';
// export const primary5 = '#888';
// export const primary6 = '#333';

export const primary16 = '#EBFFFB';

export const errorColor = '#ef6565';
export const lightErrorColor = '#ef9c9c';
export const goodColor = '#53C18C';

export const cleanBorder = '#D0EFE6';
export const lightBorder = '#B2E6C7';
